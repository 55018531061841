// custom typefaces
import "typeface-montserrat"

import "./src/styles/dist/_GenericStyles.css"
import "./src/styles/dist/_Variables.css"
import "./src/styles/pages/dist/Blog.css"
import "./src/styles/pages/dist/Index.css"
import "./src/styles/pages/dist/Causes.css"
import "./src/styles/pages/dist/Twitch.css"
import "./src/styles/bootstrap/scss/dist/bootstrap.css"

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload();